import React, {Component} from 'react';
import PageMobile from "./page-mobile";

const $dataMobile = window.mobile;

class PageMobileContainer extends Component {
  render() {
    return (
      <PageMobile data={$dataMobile} />
    );
  }
}

export default PageMobileContainer;
