import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import Collapse from "@material-ui/core/Collapse";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Typography} from "@material-ui/core";
import IconClose from "../../icons/icon-close";
import Grid from "@material-ui/core/Grid";
import Item from "../item";
import InArgentina from "./components/in-argentina";
import Hidden from "@material-ui/core/Hidden";
import Utils from "../../utils/utils";
import Animation from "./components/animation";
import Share from "../share";
import 'typeface-b612-mono';
import {withRouter} from "react-router";

class Clock extends Component {
  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      open: false,
      hover: false,
    }
  }

  onOpen() {
    this.setState((state) => ({open: !state.open}));
  }

  onMouseEnter() {
    this.setState({hover: true});
  }

  onMouseLeave() {
    this.setState({hover: false});
  }

  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (this.props.slug !== hash) {
      return;
    }
    if (Utils.scrollTo(hash)) {
      this.setState({open: true});
    }
  }

  render() {
    let { open, hover } = this.state;
    let { classes, icon, slug, main, topItems, inArgentina, bottomItems } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(main.text, '**clock**');
    let textAfterHighlight = Utils.getTextAfterHighlight(main.text, '**clock**');
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <Fragment>
        <Grid
          id={slug}
          container
          alignItems="center"
          className={clsx(classes.root, (open || hover) && classes.rootOpen)}
          onClick={this.onOpen}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <Grid item xs={11}>
            <IconComponent fontSize="default" display="inline" selected={open || hover} />
            <Typography display="inline" className={clsx(classes.text, (open || hover) && classes.textOpen)}>
              {textBeforeHighlight}
              <span className={classes.textHighlight}>
                {main.clock.prepend}<Animation valuePerSecond={main.clock.valuePerSecond} />{main.clock.append}
              </span>
              {textAfterHighlight}
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            {open && <IconClose fontSize="small" display="inline" className={clsx(classes.icon, open && classes.iconHover)} />}
          </Grid>
        </Grid>
        <Collapse in={open}>
          <Grid container className={classes.collapseContainer}>
            {topItems.map((item, index) =>
              <Fragment key={index}>
                {index === 0 &&
                  <Fragment>
                    <Grid item xs={10}>
                      <Item text={item.text} highlight={item.highlight} sources={item.sources}/>
                    </Grid>
                    <Grid item xs={2} align="right">
                      <Share fragment={slug} />
                    </Grid>
                  </Fragment>
                }
                {index !== 0 &&
                  <Grid item xs={12}>
                    <Item text={item.text} highlight={item.highlight} sources={item.sources}/>
                  </Grid>
                }
              </Fragment>
            )}
            <Grid item xs={12}>
              <InArgentina icon={icon} main={inArgentina.main} all={inArgentina.all} one={inArgentina.one} note={inArgentina.note} />
            </Grid>
            {bottomItems.map((item, index) =>
              <Grid key={index} item xs={12}>
                <Item text={item.text} highlight={item.highlight} sources={item.sources} />
              </Grid>
            )}
            <Hidden mdUp>
              <Grid item xs={12} align="right">
                {open && <IconClose onClick={this.onOpen} fontSize="small" display="inline" />}
              </Grid>
            </Hidden>
          </Grid>
        </Collapse>
      </Fragment>
    );
  }
}

Clock.propTypes = {
  icon: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  main: PropTypes.shape({
    text: PropTypes.string.isRequired,
    clock: PropTypes.shape({
      valuePerSecond: PropTypes.number.isRequired,
      prepend: PropTypes.string.isRequired,
      append: PropTypes.string.isRequired,
    }),
  }).isRequired,
  topItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
  })).isRequired,
  inArgentina: PropTypes.shape({
    main: PropTypes.object.isRequired,
    all: PropTypes.object.isRequired,
    one: PropTypes.object.isRequired,
  }).isRequired,
  bottomItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
  })),
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  rootOpen: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  textOpen: {
    color: theme.palette.common.white,
  },
  textHighlight: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'B612 Mono, monospace',
  },
  collapseContainer: {
    padding: theme.spacing(2),
    backgroundColor: '#f2f2f2',
  },
  icon: {
    borderRadius: '50%',
    padding: theme.spacing(0.25),
  },
  iconHover: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
});

export default withRouter(withStyles(styles)(Clock));
