import React, {Component} from 'react';
import WidgetClocks from "./widget-clocks";

const $data = window.clocks;

class WidgetClocksContainer extends Component {
  render() {
    return (
      <WidgetClocks dataClocks={$data} />
    );
  }
}

export default WidgetClocksContainer;
