import React, {Component} from 'react';
import WidgetRankings from "./widget-rankings";

const $data = window.rankings;

class WidgetClocksContainer extends Component {
  render() {
    return (
      <WidgetRankings dataRankings={$data} />
    );
  }
}

export default WidgetClocksContainer;
