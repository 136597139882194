import React, {Component} from 'react';
import WidgetStripe from "./widget-stripe";

const $data = window.stripes;

class WidgetStripeContainer extends Component {
  render() {
    return (
      <WidgetStripe dataStripes={$data} />
    );
  }
}

export default WidgetStripeContainer;
