import React, {Component} from 'react';
import PageAbout from "./page-about";

const $dataAbout = window.about;

class PageAboutContainer extends Component {
  render() {
    return (
      <PageAbout data={$dataAbout} />
    );
  }
}

export default PageAboutContainer;
